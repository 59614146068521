<template>
  <div>
    <v-card-text class="px-0 py-0">
      <v-stepper elevation="0" v-model="currentStep">
        <v-stepper-items>
          <v-stepper-content
            :complete="currentStep > lastStep"
            step="1"
            class="px-0 py-0"
          >
            <v-form
              v-model="valid"
              ref="form-1"
              @submit.prevent="handlerNextStep()"
            >
              <v-col cols="12" class="px-0 py-0">
                <p class="grey--text">
                  Your partner must also be a registered patient who has
                  activated Prelude Connect. Please enter these fields exactly
                  how they are shown in your partner's Prelude Connect app.
                </p>
              </v-col>
              <v-row class="px-0">
                <v-col>
                  <v-text-field
                    label="First Name"
                    v-model="data.firstName"
                    :rules="rules.firstName"
                    validate-on-blur
                    :disabled="loading"
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    label="Last Name"
                    v-model="data.lastName"
                    :rules="rules.lastName"
                    validate-on-blur
                    :disabled="loading"
                  />
                </v-col>
              </v-row>
              <v-row class="px-0">
                <v-col class="mt-0 pt-0">
                  <DateField
                    v-model="data.dateOfBirth"
                    label="Date of Birth"
                    :rules="rules.dateOfBirth"
                    :disabled="loading"
                  ></DateField>
                </v-col>

                <v-col>
                  <v-text-field
                    label="Email"
                    v-model="data.email"
                    :rules="rules.email"
                    validate-on-blur
                    class="pt-0"
                    :disabled="loading"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    :items="relationshipOptions"
                    label="Relationship"
                    v-model="data.relationship"
                    :rules="rules.relationship"
                    validate-on-blur
                    class="pt-0"
                    menu-props="auto"
                    :disabled="loading"
                  ></v-select>
                </v-col>
              </v-row>
              <v-switch
                class="ml-1"
                v-model="data.allowPartnerSeeInformation"
                :rules="rules.allowPartnerSeeInformation"
                messages="You can allow your partner to view your calendar, messages, labs, documents, appointments and payments. You can stop sharing this information at any time."
                :persistent-hint="true"
                label="Allow my partner to see my information"
                :disabled="loading"
              ></v-switch>

              <v-btn
                class="mt-8"
                color="primary"
                rounded
                block
                large
                type="submit"
                :disabled="!valid"
                :loading="loading"
              >
                Review Sharing Consent
              </v-btn>
            </v-form>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-form
              v-model="valid"
              ref="form-2"
              lazy-validation
              @submit.prevent="handlerNextStep()"
            >
              <v-col cols="12" class="px-0 py-0 pb-4">
                <p class="black--text" v-if="data">
                  You are inviting {{ data.firstName }} {{ data.lastName }},
                  date of birth {{ showDate(data.dateOfBirth) }} to connect as
                  your {{ data.relationship }}.
                </p>
                <p class="black--text">
                  By linking partner accounts in Prelude Connect you authorize
                  your designated partner to receive any and all protected
                  health information maintained by your clinic (whether in
                  Prelude Connect or otherwise), including but not limited to
                  History/Physical Exams, Past/Present Medications, Lab Results,
                  Physician’s Orders, Patient Allergies, Operation Reports,
                  Consultation Reports, Progress Notes, Discharge Summary(ies),
                  Diagnostic Test Reports, EKG/Cardiology Reports, Pathology
                  Reports, Billing Information, Radiology Reports & Images.
                  Further, I specifically consent to the release of the
                  following information:
                </p>
              </v-col>

              <v-checkbox
                class="grey--text align"
                label="Mental Health Records (including psychotherapy
              notes)."
                v-model="data.check_1"
              />
              <v-checkbox
                class="grey--text align"
                label="Genetic Information (including Genetic Test
              Results)."
                v-model="data.check_2"
              />
              <v-checkbox
                class="grey--text align"
                label="Drug, Alcohol, or Substance Abuse Records."
                v-model="data.check_3"
              />
              <v-checkbox
                class="grey--text align"
                label="HIV/AIDS Test Results/Treatment."
                v-model="data.check_4"
              />

              <v-btn
                class="mt-8"
                color="primary"
                :disabled="
                  !data.check_1 ||
                  !data.check_2 ||
                  !data.check_3 ||
                  !data.check_4
                "
                rounded
                block
                large
                @click="consentConfirmation"
              >
                Agree & Send Invitation
              </v-btn>
            </v-form>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
    <v-spacer />
  </div>
</template>

<script>
import { auth, api } from '../../sharedPlugin';
import DateField from '../../components/inputs/DateField';

export default {
  components: { DateField },
  data() {
    return {
      valid: false,
      loading: false,
      currentStep: 1,
      lastStep: 2,
      relationshipOptions: ['Spouse', 'Partner', 'Life Partner', 'Companion'],
      modals: {
        dateOfBirth: false
      },
      consent: null,
      data: {
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        email: '',
        relationship: '',
        allowPartnerSeeInformation: false,
        check_1: false,
        check_2: false,
        check_3: false,
        check_4: false
      },
      rules: {
        firstName: [(v) => !!v || 'First name is required'],
        lastName: [(v) => !!v || 'Last name is required'],
        dateOfBirth: [
          (v) => !!v || 'Date of birth is required',
          (v) => new Date(v) < Date.now() || 'The date of birth must be valid'
        ],
        email: [
          (v) => !!v || 'Email is required',
          (v) => /.+@.+/.test(v) || 'Email must be valid'
        ],
        relationship: [(v) => !!v || 'Relationship is required'],
        allowPartnerSeeInformation: [(v) => !!v || '']
      }
    };
  },
  computed: {
    ...auth.mapComputed(['user'])
  },
  mounted() {
    this.loadConsents();
  },
  methods: {
    titleCase(text) {
      return text
        .toLowerCase()
        .split(' ')
        .map((word) => {
          return word && word.length > 0
            ? word[0].toUpperCase() + word.slice(1)
            : '';
        })
        .join(' ');
    },
    dateOfBirthAllowedDates: (val) => {
      return new Date(val) < new Date();
    },
    handlerNextStep() {
      this.valid = false;
      this.loading = true;
      let v = this.$refs[`form-${this.currentStep}`].validate();
      if (v) {
        this.valid = true;

        if (this.currentStep === 1) {
          this.currentStep++;
        }
      }
    },
    async loadConsents() {
      this.loading = true;
      await api.PartnerConnect.getConsents()
        .then((res) => {
          if (res && res.length > 0) {
            this.consent = res[0];
            if (this.consent && this.consent.text) {
              this.consent.text = this.consent.text.replace(/<[^>]+>/g, '');
            }
          }
        })
        .catch(() => {
          this.$store.commit('showSnackbar', {
            message: 'Consent unavailable',
            color: 'red'
          });
        })
        .finally(() => (this.loading = false));
    },
    async consentConfirmation() {
      this.loading = true;
      this.data.allowPartnerSeeInformation = this.data
        .allowPartnerSeeInformation
        ? 1
        : 0;
      this.data.sharingExpiration = '2222-02-02';
      this.data.dateOfBirth = this.getDate(this.data.dateOfBirth);

      await api.PartnerConnect.invitePartner(this.data)
        .then((res) => {
          if (!res) {
            return this.$modal.show({
              text: 'We tried to match the information you submitted and could not locate your partner in our records. Please review your information or contact the clinic.',
              buttons: [
                {
                  text: 'Ok',
                  color: 'red',
                  onAction: () => {
                    this.$router.push('/my-partner');
                  }
                }
              ]
            });
          } else {
            let partnerResponse = res && res.length > 0 ? res[0] : null;
            if (
              partnerResponse &&
              partnerResponse.isConnected &&
              partnerResponse.isConnected == 1
            ) {
              return this.$modal.show({
                text: `You already have a connection with ${this.titleCase(
                  partnerResponse.firstName
                )} ${this.titleCase(partnerResponse.lastname)}.`,
                buttons: [
                  {
                    text: 'Ok',
                    color: 'primary white--text',
                    onAction: () => {
                      this.$router.push('/my-partner');
                    }
                  }
                ]
              });
            } else if (partnerResponse && partnerResponse.error) {
              return this.$modal.show({
                text: partnerResponse.error,
                buttons: [
                  {
                    text: 'Ok',
                    color: 'primary white--text',
                    onAction: () => {
                      this.$router.push('/my-partner');
                    }
                  }
                ]
              });
            } else {
              return this.$modal.show({
                text: "The invitation has been sent, awaiting your partner's confirmation.",
                buttons: [
                  {
                    text: 'Ok',
                    color: 'primary white--text',
                    onAction: () => {
                      this.$router.push('/my-partner');
                    }
                  }
                ]
              });
            }
          }
        })
        .catch((err) => {
          this.$store.commit('showSnackbar', {
            message: 'Unable to invite new partner',
            color: 'red'
          });
        })
        .finally(() => (this.loading = false));
    },
    formatDate(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [year, month, day] = date.split('-');
        return `${month}/${day}/${year}`;
      }
    },
    getDate(date) {
      date = date.split('T')[0];
      date = date.split('-');
      var month = date[1];
      var day = date[2];
      var year = date[0];

      return `${year}-${month}-${day}`;
    },
    showDate(date) {
      date = date.split('T')[0];
      date = date.split('-');
      var month = date[1];
      var day = date[2];
      var year = date[0];

      return `${month}/${day}/${year}`; //`${year}-${month}-${day}`;
    },
    parseDate(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [month, day, year] = date.split('/');
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
      }
    }
  }
};
</script>

<style scoped>
.theme--primary.v-card {
  color: '#1F6070';
}
</style>
